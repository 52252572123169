/**
 * index
 */

import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Const from '../const'
// components
import Header from '../components/Header'
import List from '../components/List'
import { Container, Layout, LayoutMain, LayoutLeft, LayoutAside } from '../components/Layout'

// const Pagination = ({ props }) => {
//   const { pageContext } = props
//   const { previousPagePath, nextPagePath } = pageContext
//
//   return (
//     <div className="">
//       { previousPagePath && <Link to={ previousPagePath }>前</Link> }
//       { nextPagePath && <Link to={ nextPagePath }>次</Link> }
//     </div>
//   )
// }

const IndexPage = memo(({ data }) => {
  // 記事一覧
  const { edges = [] } = data.allMarkdownRemark

  return (
    <Container>
      <Helmet>
        <title>{ Const.SITE_NAME }</title>
      </Helmet>

      <Header />

      <Layout>
        <LayoutLeft />

        <LayoutMain>
          <List items={ edges } />
        </LayoutMain>

        <LayoutAside />
      </Layout>
    </Container>
  )
})

export const query = graphql`
query {
  allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    filter: { frontmatter: { active: { eq: 1 } } }
    limit: 1000
  ) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY/MM/DD")
          title
          tags
        }
      }
    }
  }
}
`

export default IndexPage
